import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FaTimes, FaLocationArrow } from 'react-icons/fa';
import { debounce } from 'lodash';
import { toast } from 'sonner';
import { useAPI } from '../../../apis/api_context';
import GetLocationService from '../../../apis/get_current_location';

const AddressModal = ({
	isOpen,
	onClose,
	onSelectAddress,
	initialAddress = '',
	hasPhysicalLocation = true,
}) => {
	const apiservice = useAPI();
	const [baddress, setBaddress] = useState(initialAddress);
	const [addressSuggestions, setAddressSuggestions] = useState([]);
	const [isGettingLocation, setIsGettingLocation] = useState(false);
	const addressInputRef = useRef(null);
	const modalRef = useRef(null);

	useEffect(() => {
		if (isOpen && addressInputRef.current) {
			addressInputRef.current.focus();
		}
	}, [isOpen]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				onClose();
			}
		};
		if (isOpen) {
			document.addEventListener('mousedown', handleClickOutside);
		}
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [isOpen, onClose]);

	const handleAddressSearch = (value) => {
		setBaddress(value);
		debouncedAddressSearch(value);
	};

	const selectAddress = (address) => {
		const formattedAddress =
			address.formatted_address || address.place_name;
		onSelectAddress(formattedAddress);
		setBaddress(formattedAddress);
		setAddressSuggestions([]);
		onClose();
	};

	const debouncedAddressSearch = useCallback(
		debounce(async (value) => {
			if (value.length > 2) {
				try {
					const addresses = await apiservice.searchAddresses(value);
					setAddressSuggestions(addresses);
				} catch (error) {
					// console.error('Error searching addresses:', error);
					toast.error('Error searching addresses. Please try again.');
				}
			} else {
				setAddressSuggestions([]);
			}
		}, 300),
		[],
	);

	const locationGetter = async () => {
		setIsGettingLocation(true);
		try {
			const result = await GetLocationService(
				apiservice.getCurrentUserLocation,
			);
			// // console.log(result);
			if (result && result.address) {
				const formattedAddress =
					result.address.formatted_address ||
					result.address.place_name;
				setBaddress(formattedAddress);
				onSelectAddress(formattedAddress);
			} else {
				throw new Error('Invalid location data received');
			}
		} catch (error) {
			// console.error('Error in locationGetter:', error);
			toast.error(
				'Error getting current location. Please try again or enter your address manually.',
			);
		} finally {
			setIsGettingLocation(false);
			onClose();
		}
	};

	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 bg-primary bg-opacity-50 z-50 flex items-center justify-center modal-overlay'>
			<div
				ref={modalRef}
				className='bg-white rounded-lg max-w-md w-full mx-4 p-4 relative'>
				<div className='flex items-center justify-between mb-4'>
					<h2 className='text-xl font-semibold '>
						Enter your store address
					</h2>
					<button
						onClick={onClose}
						className='bg-gray-100 text-primary p-2 rounded-full'>
						<FaTimes />
					</button>
				</div>
				<input
					ref={addressInputRef}
					type='text'
					value={baddress}
					onChange={(e) => handleAddressSearch(e.target.value)}
					placeholder='Search for an address'
					className='w-full h-[48px] rounded-lg bg-bgNeutral py-[14px] px-[12px] text-[15px] font-[400] placeholder-gray-400 leading-[20px] text-primary border border-[#e3e3e3] focus:border-green-600'
					autoFocus
				/>
				<div
					className={`bg-gray-100 mt-2 p-3 rounded-lg flex space-x-3 items-center ${
						hasPhysicalLocation
							? 'cursor-pointer'
							: 'cursor-not-allowed opacity-50'
					}`}
					onClick={() => hasPhysicalLocation && locationGetter()}>
					<FaLocationArrow />
					<div>
						<p className='font-medium'>Use my Current Location</p>
						<p className='text-[14px] text-gray-500'>
							{isGettingLocation
								? 'Getting location...'
								: 'Click to get current location'}
						</p>
					</div>
				</div>
				<ul className='space-y-2 max-h-60 overflow-auto mt-4'>
					{addressSuggestions.map((address, index) => (
						<li
							key={index}
							className='flex items-center p-2 hover:bg-gray-100 cursor-pointer'
							onClick={() => {
								selectAddress(address);
								onClose();
							}}>
							<FaLocationArrow className='mr-2' />
							<div>
								<p className='font-medium'>
									{address.formatted_address ||
										address.place_name}
								</p>
								<p className='text-[14px] text-gray-500'>
									{address.description}
								</p>
							</div>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default AddressModal;

import React from 'react';
import Button from './button';
import { TbTrash } from 'react-icons/tb';

const DeleteModal = ({ isOpen, onClose, onConfirm, itemName }) => {
	if (!isOpen) return null;

	return (
		<div className='fixed inset-0 flex items-center justify-center z-[1000] bg-primary bg-opacity-50'>
			<div className='bg-white rounded-xl p-6 w-96'>
				<h2 className='text-lg font-semibold mb-4'>Confirm Deletion</h2>
				<p>Are you sure you want to delete "{itemName}"?</p>
				<div className='mt-6 flex justify-end'>
					<Button
						type='secondary'
						label='Cancel'
						onClick={onClose}
						className='mr-2'
					/>
					<Button
						type='primary'
						label='Delete'
						onClick={onConfirm}
						iconLeft={<TbTrash />}
					/>
				</div>
			</div>
		</div>
	);
};

export default DeleteModal;

import React, {
	useState,
	useEffect,
	useMemo,
	useCallback,
	useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataDisplayRenderer from '../components/DataDisplay';
import { Fa42Group } from 'react-icons/fa6';
import { useAPI } from '../../../apis/api_context';

import OrderModal from '../components/orderDetails';
import Layout from '../../../Layout';
import Dropdown from '../components/dropdown';

const Orders = () => {
	const apiservice = useAPI();
	const navigate = useNavigate();
	const location = useLocation();
	const [orders, setOrders] = useState([]);
	const [selectedOrder, setSelectedOrder] = useState();
	const [loading, setLoading] = useState(true);
	const [orderModalOpen, setOrderModalOpen] = useState(false);
	const [selectedOrderStatus, setSelectedOrderStatus] =
		useState('All Orders');

	const handleOpenOrderDetails = (order) => {
		setOrderModalOpen(!orderModalOpen);
		setSelectedOrder(order);
	};

	const uniqueStatuses = useMemo(() => {
		const statuses = orders.map((order) => order.currentStatus);
		return [...new Set(statuses)];
	}, [orders]);

	const statusItems = uniqueStatuses.map((status) => ({ name: status }));
	const orderDropDownItems = useMemo(() => {
		return [{ _id: 'all-orders', name: 'All Orders' }, ...statusItems];
	}, [statusItems]);

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState({});

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};
	const filterHeaderRef = useRef(null);

	const tabs = useMemo(() => {
		const handleSelect = (option) => {
			setSelectedOrderStatus(option);
			handleCloseDropdown();
		};

		const handleToggleDropdown = (e) => {
			const rect = filterHeaderRef.current.getBoundingClientRect();
			setDropdownPosition({
				top: rect.bottom + window.scrollY, // Position dropdown below the header
				left: rect.left + window.scrollX, // Align dropdown with the header
			});
			setIsDropdownOpen(!isDropdownOpen);
		};
		const activeOrders =
			orders
				.filter((order) => !order.completed)
				.map((order) => ({
					_id: order._id,
					orderId: order.customId,
					created: new Date(order.createdAt).toLocaleString(),
					customer: order.customer.name,
					items: order.products.length,

					rawStatus: order.currentStatus,
				})) || [];

		const filteredActiveOrders =
			selectedOrderStatus === 'All Orders'
				? activeOrders
				: activeOrders.filter(
						(order) => order?.rawStatus === selectedOrderStatus,
				  );

		const completedOrders =
			orders
				.filter((order) => order.completed)
				.map((order) => ({
					_id: order._id,
					orderId: order.customId,
					totalCartValue: order.paymentDetails.totalCartValue,
					deliveryCost: order.paymentDetails.deliveryCost,
					status: order.currentStatus,
					createdAt: new Date(order.createdAt).toLocaleString(),
				})) || [];
		return [
			{
				name: 'Active Orders',
				headers: ['Id', 'Created', 'Customer', 'Items', 'Status'],
				data: filteredActiveOrders,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have no active orders yet
						</p>
					</div>
				),
				filterHeader: (
					<div
						ref={filterHeaderRef}
						className='flex items-center gap-4 relative'
						onClick={handleToggleDropdown}>
						<button className='flex justify-center items-center w-6 h-6'>
							<i
								className='fi fi-br-settings-sliders inline-flex items-center justify-center h-full w-full'
								style={{ color: '#7B8783' }}></i>
						</button>
						<button
							className=''
							onClick={handleToggleDropdown}>
							{selectedOrderStatus || 'All Orders'}
						</button>
					</div>
				),
				filterDropdown: (
					<>
						{isDropdownOpen && (
							<div
								className='mt-7'
								style={{
									position: 'absolute',
									top: `${dropdownPosition.top}px`,
									left: `${dropdownPosition.left}px`,
								}}>
								<Dropdown
									actions={orderDropDownItems.map((item) => ({
										label: item.name,
										onClick: () => handleSelect(item.name),
									}))}
									onClose={handleCloseDropdown}
									width='min-w-[150px]'
								/>
							</div>
						)}
					</>
				),
			},
			{
				name: 'Order History',
				headers: [
					'Custom ID',
					'Total',
					'Delivery Cost',
					'Status',
					'Created At',
				],
				data: completedOrders,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have no completed orders yet
						</p>
					</div>
				),
			},
		];
	}, [
		orders,
		orderDropDownItems,
		selectedOrderStatus,
		dropdownPosition.left,
		dropdownPosition.top,
		isDropdownOpen,
	]);

	const [activeTab, setActiveTab] = useState(() => {
		const savedTab = localStorage.getItem('activeTab');
		return savedTab || tabs[0].name;
	});

	const [currentPage, setCurrentPage] = useState(() => {
		const savedPage = localStorage.getItem('currentPage');
		return savedPage ? parseInt(savedPage, 10) : 1;
	});

	const activeTabData = tabs.find((tab) => tab.name === activeTab) || tabs[0];

	useEffect(() => {
		localStorage.setItem('activeTab', activeTab);
		localStorage.setItem('currentPage', currentPage.toString());

		const path = `/orders/${activeTab.toLowerCase().replace(/\s+/g, '-')}`;
		if (location.pathname !== path) {
			navigate(path);
		}
	}, [activeTab, currentPage, navigate, location.pathname]);

	useEffect(() => {
		const pathTab = location.pathname.split('/')[2];
		const matchingTab = tabs.find(
			(tab) => tab.name.toLowerCase() === pathTab,
		);
		if (matchingTab) {
			setActiveTab(matchingTab.name);
		}

		return () => {
			localStorage.removeItem('activeTab');
			localStorage.removeItem('currentPage');
		};
	}, [location.pathname, tabs]);

	const fetchOrders = useCallback(async () => {
		try {
			setLoading(true);
			const response = await apiservice.getAllOrders();
			console.log(response);

			setOrders(response.data);
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}, [apiservice]);

	useEffect(() => {
		fetchOrders();
	}, [apiservice, fetchOrders]);

	return (
		<Layout>
			<div className='flex flex-col h-[86.5vh]'>
				<div className='flex-grow h-full overflow-hidden'>
					{loading ? (
						<div className='flex items-center justify-center h-full'>
							<p className='text-xl font-semibold text-gray-500'>
								Loading orders...
							</p>
						</div>
					) : (
						<DataDisplayRenderer
							tabs={tabs}
							defaultView={activeTabData.view}
							setActiveTab={setActiveTab}
							activeTab={activeTab}
							onTableClick={(item) => {
								handleOpenOrderDetails(item);
							}}
							onActionClick={(item) => [
								{
									label: 'View Details',
									onClick: () => {
										handleOpenOrderDetails(item);
									},
								},
							]}
							emptyStateMessage={activeTabData.emptyStateMessage}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							filterHeader={activeTabData.filterHeader}
							filterDropdown={activeTabData.filterDropdown}
						/>
					)}
					{orderModalOpen && (
						<OrderModal
							order={selectedOrder}
							onClose={() => {
								setOrderModalOpen(false);
								fetchOrders();
							}}
						/>
					)}
				</div>
			</div>
		</Layout>
	);
};

export default Orders;

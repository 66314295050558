import { toast } from 'sonner';
import { useAPI } from '../../../apis/api_context';
import { FaTimes } from 'react-icons/fa';
import Button from './button';
import { useAuth } from '../../../apis/auth_context';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const PricingCard = ({
	tier,
	price,
	features,
	isRecommended,
	isCurrentPlan,
	ctaText,
	ctaAction,
	highlight = false,
}) => {
	const getBadgeStyles = () => {
		if (isCurrentPlan) return 'text-gray-500';
		if (highlight) return 'text-basegreen';
		return 'text-basegreen ';
	};

	const getButtonStyles = () => {
		if (isCurrentPlan) return 'bg-gray-200 cursor-not-allowed';
		if (highlight) return 'bg-basegreen hover:bg-[#0B6E4FE5] text-white';
		return 'bg-white border-2 border-black hover:bg-gray-50';
	};

	return (
		<div className='bg-gray-50  rounded-xl p-6 flex flex-col relative overflow-hidden'>
			<div className='flex justify-between items-center'>
				<p className={`text-sm font-medium ${getBadgeStyles()}`}>
					{tier.toUpperCase()}
				</p>
				{isRecommended && highlight && (
					<div className='bg-basegreen text-white px-3 py-1 rounded-full text-sm'>
						Recommended
					</div>
				)}
			</div>

			<div className='mb-8'>
				{price !== null && price !== undefined && (
					<div className='mt-4 flex items-end '>
						<p className='text-sm mr-1'>₦</p>
						<p className='text-[28px] font-semibold leading-none'>
							{price.toLocaleString()}{' '}
						</p>
						<span className='text-gray-600 ml-2'>/month</span>
					</div>
				)}
			</div>

			<div className='flex-grow space-y-3 mb-12'>
				{features.map((feature, index) => (
					<div
						key={index}
						className='flex items-center text-gray-500'>
						<span
							className={`mr-2 ${
								highlight ? 'text-basegreen' : ''
							}`}>
							✓
						</span>
						{feature}
					</div>
				))}
			</div>

			<button
				onClick={ctaAction}
				className={`w-full py-4 rounded-full font-medium transition-colors ${getButtonStyles()}`}
				disabled={isCurrentPlan}>
				{ctaText}
			</button>
		</div>
	);
};

export const SubscriptionModal = ({
	isOpen,
	onClose,
	currentTier,
	forceOpen = false,
}) => {
	const apiService = useAPI();
	const { refreshUserData } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams();
	const [statusMessage, setStatusMessage] = useState('');
	// Consolidated state: 'idle', 'processing', 'verifying'
	const [processingState, setProcessingState] = useState('idle');
	const [status, setStatus] = useState(null);
	const [plan, setPlan] = useState(null);
	const [modalView, setModalView] = useState('subscription'); // 'subscription' or 'status'
	const [errorDetails, setErrorDetails] = useState('');
	const [verificationTimeoutId, setVerificationTimeoutId] = useState(null);
	const callbackUrl = window.location.href;

	// Function to reset modal state and clean up any pending operations
	const resetModalState = () => {
		setModalView('subscription');
		setStatus(null);
		setErrorDetails('');
		setProcessingState('idle');
		
		// Clear any pending verification timeout
		if (verificationTimeoutId) {
			clearTimeout(verificationTimeoutId);
			setVerificationTimeoutId(null);
		}
	};

	// Timeout for payment verification (30 seconds)
	const VERIFICATION_TIMEOUT = 30000;
	const handlePaymentCallback = async (reference, callbackUrl) => {
		// Safety check - if no reference, don't proceed
		if (!reference) {
			console.error('Payment verification failed: No reference provided');
			return;
		}

		// Clear any existing verification timeout
		if (verificationTimeoutId) {
			clearTimeout(verificationTimeoutId);
		}

		setProcessingState('verifying');

		// Set up timeout for verification - store it in state for cleanup
		const timeoutId = setTimeout(() => {
			setStatus('timeout');
			setStatusMessage(
				'Verification is taking longer than expected. Please check your account status later or contact support.',
			);
			setErrorDetails('Verification timeout exceeded');
			setModalView('status');
			setProcessingState('idle');
			setVerificationTimeoutId(null);
		}, VERIFICATION_TIMEOUT);
		
		setVerificationTimeoutId(timeoutId);

		try {
			const response = await apiService.handlePaystackCallback(
				reference,
				callbackUrl,
			);

			// Clear timeout since we got a response
			clearTimeout(timeoutId);
			setVerificationTimeoutId(null);

			const urlParams = new URLSearchParams(response);
			const subscriptionStatus = urlParams.get('subscriptionStatus');
			const planType = urlParams.get('plan');

			setStatus(subscriptionStatus);
			setPlan(planType);

			if (subscriptionStatus === 'success') {
				setStatusMessage(
					`Your subscription to ${
						planType || 'the new plan'
					} was successful!`,
				);
				refreshUserData();
			} else if (subscriptionStatus === 'pending') {
				setStatusMessage(
					'Your payment is being processed. We will notify you once completed.',
				);
			} else {
				setStatusMessage(
					'There was an issue processing your subscription. Please try again or contact support.',
				);
				setErrorDetails(subscriptionStatus || 'Unknown payment error');
			}
			setModalView('status');
		} catch (error) {
			// Clear timeout since we got a response (error)
			clearTimeout(timeoutId);
			setVerificationTimeoutId(null);

			console.error('Payment verification failed:', error);
			setStatus('error');
			setStatusMessage(
				"We couldn't verify your payment. Please contact support if your account was debited.",
			);
			setErrorDetails(
				error?.message || 'Network or server error during verification',
			);
			setModalView('status');
		} finally {
			setProcessingState('idle');
		}
	};

	const features = {
		free: [
			'Sell up to 100 products',
			'Basic business reports',
			'Free online store',
		],
		standard: [
			'Sell up to 500 products',
			'Business alerts',
			'Create coupons',
			'Email & SMS campaigns',
			'Priority support',
			'24/7 monitoring',
			'Real-time tracking',
		],
		premium: [
			'Unlimited products',
			'Team management',
			'Bulk uploads',
			'Multiple locations',
			'API access',
			'Dedicated manager',
			'All Standard features',
		],
	};
	const handleSubscriptionChange = async (plan) => {
		const currentTierLower = currentTier.toLowerCase();
		const planLower = plan.toLowerCase();

		// Check if it's the same plan (no change)
		if (currentTierLower === planLower) {
			toast(`Already on ${plan}`);
			return;
		}

		// Reset any previous error details
		setErrorDetails('');

		// Check if this is a downgrade
		const isDowngrade =
			(currentTierLower === 'premium' &&
				(planLower === 'free' || planLower === 'standard')) ||
			(currentTierLower === 'standard' && planLower === 'free');

		// Check if this is an upgrade
		const isUpgrade =
			(currentTierLower === 'free' &&
				(planLower === 'standard' || planLower === 'premium')) ||
			(currentTierLower === 'standard' && planLower === 'premium');

		try {
			// Handle downgrades - no loading state needed as these complete immediately
			if (isDowngrade) {
				setProcessingState('processing');

				const response = await apiService.downgradeSubscription(
					plan,
					callbackUrl,
				);

				toast.success(`Plan downgraded to ${plan} successfully`);
				refreshUserData();

				// Don't auto-close the modal
				setStatus('success');
				setStatusMessage(
					`Your subscription has been downgraded to ${plan}`,
				);
				setModalView('status');
				setProcessingState('idle');
			}
			// Handle upgrades - requires payment and verification
			else if (isUpgrade) {
				setProcessingState('processing');

				const response = await apiService.upgradeSubscription(
					plan,
					callbackUrl,
				);

				if (response && response.paymentUrl) {
					// Redirect to payment URL
					window.location.href = response.paymentUrl;
				} else {
					toast.error('Unable to get payment URL. Please try again.');
					setErrorDetails(
						'No payment URL was returned from the server',
					);
					setProcessingState('idle');
				}
			}
		} catch (error) {
			console.error('Subscription change failed:', error);

			// Provide more detailed error messages
			let errorMessage = 'Unknown error occurred';

			if (error.message) {
				errorMessage = error.message;
			} else if (error.response && error.response.data) {
				errorMessage = error.response.data.message || 'Server error';
			} else if (!navigator.onLine) {
				errorMessage =
					'Network connection issue. Please check your internet connection.';
			}

			toast.error(`Subscription change failed: ${errorMessage}`);
			setErrorDetails(errorMessage);
			setStatus('error');
			setStatusMessage(
				`Subscription change to ${plan} failed: ${errorMessage}`,
			);
			setModalView('status');
			setProcessingState('idle');
		}
	};

	const plans = [
		{
			tier: 'Free',
			price: 0,
			features: features.free,
			isCurrentPlan: currentTier === 'Free',
			ctaText:
				currentTier === 'Free' ? 'Current Plan' : 'Downgrade to Free',
			ctaAction: () => {
				if (currentTier !== 'Free') handleSubscriptionChange('Free');
			},
		},
		{
			tier: 'Standard',
			price: 4999,
			features: features.standard,
			isRecommended: currentTier === 'Free',
			highlight: true,
			isCurrentPlan: currentTier === 'Standard',
			ctaText:
				currentTier === 'Standard'
					? 'Current Plan'
					: currentTier === 'Premium'
					? 'Downgrade to Standard'
					: 'Upgrade to Standard',
			ctaAction: () => {
				if (currentTier !== 'Standard')
					handleSubscriptionChange('Standard');
			},
		},
		{
			tier: 'Premium',
			price: 9499,
			features: features.premium,
			isRecommended: currentTier === 'Standard',
			isCurrentPlan: currentTier === 'Premium',
			ctaText:
				currentTier === 'Premium'
					? 'Current Plan'
					: 'Upgrade to Premium',
			ctaAction: () => {
				if (currentTier !== 'Premium')
					handleSubscriptionChange('Premium');
			},
		},
	];

	// Effect to handle modal opening - reset state
	useEffect(() => {
		if (isOpen) {
			// If the modal is newly opened, reset any stale verification state
			if (processingState === 'verifying') {
				resetModalState();
			}
		} else {
			// Always reset state when modal closes
			resetModalState();
		}
	}, [isOpen]);

	// Effect to handle URL reference parameter for payment callback
	useEffect(() => {
		const reference = searchParams.get('reference');
		if (reference) {
			// Open the modal if it's not already open
			if (!isOpen && forceOpen) {
				onClose(false); // Call onClose with false to open the modal
			}
			
			handlePaymentCallback(reference, callbackUrl);

			// Remove the reference from the URL without page reload
			const newParams = new URLSearchParams(searchParams);
			newParams.delete('reference');
			setSearchParams(newParams, { replace: true });
		}
	}, [searchParams, apiService, callbackUrl, isOpen]);

	if (!isOpen) return null;

	return (
		<div
			className='fixed inset-0 bg-primary bg-opacity-50 z-[1000] flex items-center justify-center overflow-y-auto'
			onClick={(e) => {
				if (processingState === 'idle') {
					resetModalState();
					onClose();
				}
				e.stopPropagation();
			}}>
			<div
				className='bg-white max-h-[90vh] overflow-y-auto rounded-2xl w-full max-w-6xl mx-4 relative'
				onClick={(e) => e.stopPropagation()}>
				<button
					onClick={(e) => {
						e.stopPropagation();
						if (processingState === 'idle') {
							resetModalState();
							onClose();
						}
					}}
					disabled={processingState !== 'idle'}
					className={`bg-gray-100 text-primary p-2 rounded-full absolute right-4 top-4 ${
						processingState !== 'idle'
							? 'opacity-50 cursor-not-allowed'
							: 'hover:text-gray-700'
					} text-xl`}>
					<FaTimes />
				</button>
				{modalView === 'subscription' ? (
					<>
						<div className='text-center px-6 pt-12 pb-8'>
							<h2 className='text-[21px] font-medium mb-1'>
								Choose the right plan for your business
							</h2>
							<p className='text-gray-500 max-w-2xl mx-auto'>
								Scale your business with features that match
								your growth stage
							</p>
						</div>
						<div className='grid grid-cols-1 md:grid-cols-3 gap-6 p-6'>
							{processingState !== 'idle' ? (
								<div className='col-span-3 flex flex-col justify-center items-center py-12'>
									<div className='flex items-center mb-4'>
										<div className='animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-basegreen'></div>
										<p className='ml-4 text-gray-600'>
											{processingState === 'verifying'
												? 'Verifying your payment...'
												: 'Processing your subscription change...'}
										</p>
									</div>
									
									{processingState === 'verifying' && (
										<button 
											onClick={() => {
												if (verificationTimeoutId) {
													clearTimeout(verificationTimeoutId);
													setVerificationTimeoutId(null);
												}
												setStatus('cancelled');
												setStatusMessage('Verification was cancelled. You can try again or check your account status later.');
												setModalView('status');
												setProcessingState('idle');
											}}
											className="mt-4 text-sm text-red-600 hover:text-red-800 underline">
											Cancel Verification
										</button>
									)}
								</div>
							) : (
								plans.map((plan, index) => (
									<PricingCard
										key={index}
										{...plan}
									/>
								))
							)}
						</div>

						<div className='text-center px-6 py-8 bg-gray-50 mt-6 rounded-b-2xl flex flex-col items-center justify-center'>
							<p className='text-gray-600'>
								Need help choosing? Let's discuss your business
								needs
							</p>
							<Button
								type='tertiary'
								label={'Talk to sales'}
							/>
						</div>
					</>
				) : (
					<div className='p-8 text-center'>
						<h3 className='text-lg font-medium mb-4'>
							{status === 'success'
								? 'Subscription Successful'
								: status === 'error'
								? 'Subscription Error'
								: status === 'timeout'
								? 'Verification Timeout'
								: status === 'cancelled'
								? 'Verification Cancelled'
								: 'Subscription Status'}
						</h3>
						<p className='text-gray-600 mb-6'>
							{statusMessage ||
								'Your subscription status has been updated.'}
						</p>
						{errorDetails && (
							<div className="mb-6 p-3 bg-red-50 border border-red-100 rounded-md text-sm text-red-700">
								<p className="font-medium mb-1">Error details:</p>
								<p>{errorDetails}</p>
							</div>
						)}
						<div className="flex justify-center space-x-4">
							<Button
								type='primary'
								label={status === 'success' ? 'Close' : 'Try Again'}
								onClick={() => {
									if (status === 'success') {
										resetModalState();
										onClose();
									} else {
										setModalView('subscription');
										setStatus(null);
										setErrorDetails('');
										setProcessingState('idle');
									}
								}}
							/>
							{status !== 'success' && (
								<Button
									type='tertiary'
									label='Close'
									onClick={() => {
										resetModalState();
										onClose();
									}}
								/>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

import { api } from './api_helper';
import { store } from '../store';

class ApiService {
	constructor() {
		this.updateMerchantIdFromStore();

		store.subscribe(() => {
			this.updateMerchantIdFromStore();
		});
	}

	updateMerchantIdFromStore() {
		const state = store.getState();
		this.merchantId = state.auth.merchantId;
	}

	// Method to get merchantId
	getMerchantId() {
		return this.merchantId;
	}

	// Product API methods
	async createProduct(formData) {
		try {
			const formDataObject = {};
			for (const [key, value] of formData.entries()) {
				// Handle files specially
				if (value instanceof File) {
					formDataObject[key] = {
						name: value.name,
						type: value.type,
						size: value.size,
					};
				} else {
					// Try to parse JSON strings
					try {
						formDataObject[key] = JSON.parse(value);
					} catch {
						formDataObject[key] = value;
					}
				}
			}

			// Log the formatted data
			// // console.log(
			// 	'Creating product with data:',
			// 	JSON.stringify(formDataObject, null, 2),
			// );

			const response = await api.post(
				`/merchant/${this.merchantId}/products`,
				formData,
				{
					withCredentials: true,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			this.trackApiCall('POST', `/merchant/${this.merchantId}/products`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/products`,
				false,
			);
			this.handleApiError('Error creating product', error);
		}
	}

	async editProduct(productId, formData) {
		try {
			const response = await api.patch(
				`/merchant/${this.merchantId}/products/${productId}`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			this.trackApiCall(
				'PATCH',
				`/merchant/${this.merchantId}/products/${productId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PATCH',
				`/merchant/${this.merchantId}/products/${productId}`,
				false,
			);
			this.handleApiError('Error editing product', error);
		}
	}

	async deleteProduct(productId) {
		try {
			const response = await api.delete(
				`/merchant/${this.merchantId}/products/${productId}`,
			);
			this.trackApiCall(
				'DELETE',
				`/merchant/${this.merchantId}/products/${productId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'DELETE',
				`/merchant/${this.merchantId}/products/${productId}`,
				false,
			);
			this.handleApiError('Error deleting product', error);
		}
	}

	async getCategories() {
		try {
			const response = await api.get(
				`merchant/${this.merchantId}/categories`,
			);
			this.trackApiCall('GET', `merchant/${this.merchantId}/categories`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'get',
				`merchant/${this.merchantId}/categories`,
				false,
			);
			this.handleApiError('Error updating category', error);
		}
	}

	async updateCategory(categoryId, category) {
		try {
			const response = await api.put(
				`merchant/${this.merchantId}/categories/${categoryId}`,
				category,
			);
			this.trackApiCall(
				'PUT',
				`merchant/${this.merchantId}/categories/${categoryId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PUT',
				`merchant/${this.merchantId}/categories/${categoryId}`,
				false,
			);
			this.handleApiError('Error updating category', error);
		}
	}

	async deleteCategory(categoryId) {
		try {
			const response = await api.delete(
				`merchant/${this.merchantId}/categories/${categoryId}`,
			);
			this.trackApiCall(
				'DELETE',
				`merchant/${this.merchantId}/categories/${categoryId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'DELETE',
				`merchant/${this.merchantId}/categories/${categoryId}`,
				false,
			);
			this.handleApiError('Error deleting category', error);
		}
	}

	async updateProductNotification(customerData, productId) {
		try {
			const response = await api.post(
				`/merchant/${this.merchantId}/customers/send-product-update-notification/${productId}`,
				customerData,
			);
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/customers/send-product-update-notification/${productId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/customers/send-product-update-notification/${productId}`,
				false,
			);
			throw error;
		}
	}

	// Store API methods
	async createStore(merchantData) {
		try {
			const response = await api.post(
				`/merchant/${this.merchantId}/store`,
				merchantData,
			);
			this.trackApiCall('POST', `/merchant/${this.merchantId}/store`);
			// // console.log(response)
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/store`,
				false,
			);
			this.handleApiError('Error creating store', error);
		}
	}

	async getStore() {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/store`,
			);
			this.trackApiCall('GET', `/merchant/${this.merchantId}/store`);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/store`,
				false,
			);
			this.handleApiError('Error fetching store data', error);
		}
	}

	async updateStoreDetails(storeData) {
		try {
			const response = await api.patch(
				`/merchant/${this.merchantId}/store/update-store-details`,
				storeData,
			);
			this.trackApiCall(
				'PATCH',
				`/merchant/${this.merchantId}/store/update-store-details`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PATCH',
				`/merchant/${this.merchantId}/store/update-store-details`,
				false,
			);
			this.handleApiError('Error updating store details', error);
		}
	}

	async uploadLogoImage(formData) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/store/upload-store-logo`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error updating store logo', error);
		}
	}

	async uploadStoreHeader(formData) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/store/upload-store-header`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
				// // console.log(response)
			);

			return response.data;
		} catch (error) {
			this.handleApiError('Error updating store header', error);
		}
	}

	async updateStoreAppearance(formData) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/store/update-store-appearance`,
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				},
			);
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/store/update-store-appearance`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/store/update-store-appearance`,
				false,
			);
			this.handleApiError('Error updating store appearance', error);
		}
	}

	async checkBusinessName(storeName) {
		try {
			const response = await api.post(
				`/merchant/${this.merchantId}/store/check`,
				{ storeName },
			);
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/store/check`,
			);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/store/check`,
				false,
			);
			this.handleApiError('Error checking business name', error);
		}
	}

	// Profile API methods
	async updatePersonalDetails(profileData) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/update-profile`,
				{
					firstName: profileData.firstName,
					lastName: profileData.lastName,
					phoneNumber: profileData.phoneNumber,
					email: profileData.email,
				},
			);
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/update-profile`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/update-profile`,
				false,
			);
			this.handleApiError('Error updating personal details', error);
		}
	}

	// Coupon API methods

	async generateCouponCode() {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/coupons/generate`,
				{
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/coupons/generate`,
			);
			// // console.log(response);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/coupons/generate`,
				false,
			);
			this.handleApiError('Error generating coupon code', error);
		}
	}
	async createCoupon(code, discountValue, expiry, maxUsage, couponType) {
		try {
			const body = {
				code: code,
				discountValue: discountValue,
				expiry: expiry,
				maxUsage: maxUsage,
				couponType: couponType,
			};
			const response = await api.post(
				`/merchant/${this.merchantId}/coupons`,
				body,
				{
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
					},
				},
			);
			this.trackApiCall('POST', `/merchant/${this.merchantId}/coupons`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/coupons`,
				false,
			);
			this.handleApiError('Error creating coupon', error);
		}
	}

	async editCoupon(couponData) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/coupons/${couponData._id}`,
				couponData,
			);
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/coupons/${couponData._id}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/coupons/${couponData._id}`,
				false,
			);
			this.handleApiError('Error editing coupon', error);
		}
	}

	async deleteCoupon(couponId) {
		try {
			const response = await api.delete(
				`/merchant/${this.merchantId}/coupons/${couponId}`,
			);
			this.trackApiCall(
				'DELETE',
				`/merchant/${this.merchantId}/coupons/${couponId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'DELETE',
				`/merchant/${this.merchantId}/coupons/${couponId}`,
				false,
			);
			this.handleApiError('Error deleting coupon', error);
		}
	}

	async getAllCoupons() {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/coupons`,
			);
			this.trackApiCall('GET', `/merchant/${this.merchantId}/coupons`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/coupons`,
				false,
			);
			this.handleApiError('Error fetching all coupons', error);
		}
	}

	// Order API methods
	async createOrder(orderData) {
		try {
			const response = await api.post(
				`/order/create/${this.merchantId}`,
				orderData,
			);
			this.trackApiCall('POST', `/order/create/${this.merchantId}`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/order/create/${this.merchantId}`,
				false,
			);
			this.handleApiError('Error creating order', error);
		}
	}

	async getAllOrders() {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/orders`,
			);
			this.trackApiCall('GET', `/merchant/${this.merchantId}/orders`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/orders`,
				false,
			);
			this.handleApiError('Error fetching all orders', error);
		}
	}

	async updateOrderStatus(orderId, status) {
		try {
			const response = await api.put(
				`/merchant/${this.merchantId}/orders/${orderId}`,
				{ status },
			);
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/orders/${orderId}`,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'PUT',
				`/merchant/${this.merchantId}/orders/${orderId}`,
				false,
			);
			this.handleApiError('Error updating order status', error);
		}
	}

	async getOrderDetails(orderId) {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/orders/${orderId}`,
			);
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/orders/${orderId}`,
			);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/orders/${orderId}`,
				false,
			);
			this.handleApiError('Error fetching order details', error);
		}
	}

	// Customer API methods
	async getCustomers() {
		try {
			const response = await api.get(
				`merchant/${this.merchantId}/customers`,
			);
			this.trackApiCall('GET', `merchant/${this.merchantId}/customers`);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`merchant/${this.merchantId}/customers`,
				false,
			);
			this.handleApiError('Error fetching customer details', error);
		}
	}

	async upgradeSubscription(plan, callbackUrl) {
		try {
			const response = await api.post(
				`merchant/${this.merchantId}/subscription/upgrade`,
				{
					plan: plan,
					callbackUrl: callbackUrl,
				},
				{ withCredentials: true },
			);
			this.trackApiCall(
				'POST',
				`merchant/${this.merchantId}/subscription/upgrade`,
			);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`merchant/${this.merchantId}/subscription/upgrade`,
				false,
			);
			this.handleApiError('Error searching addresses', error);
		}
	}
	async downgradeSubscription(callbackUrl) {
		try {
			const response = await api.post(
				`merchant/${this.merchantId}/subscription/downgrade`,
				{
					callbackUrl,
				},
				{ withCredentials: true },
			);
			this.trackApiCall(
				'POST',
				`merchant/${this.merchantId}/subscription/downgrade`,
			);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`merchant/${this.merchantId}/subscription/downgrade`,
				false,
			);
			this.handleApiError('Error searching addresses', error);
		}
	}

	// Bank API methods
	async updateBankDetails(profileData) {
		try {
			const response = await api.post(
				`/merchant/${this.merchantId}/add-settlement-account`,
				profileData,
			);
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/add-settlement-account`,
			);
			// // console.log(response);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'POST',
				`/merchant/${this.merchantId}/add-settlement-account`,
				false,
			);
			this.handleApiError('Error updating bank details', error);
		}
	}

	async getBanksList() {
		try {
			const response = await api.get('/payment/getbankslist', {
				withCredentials: true,
			});
			this.trackApiCall('GET', '/payment/getbankslist');
			return response.data.data.banks.data;
		} catch (error) {
			this.trackApiCall('GET', '/payment/getbankslist', false);
			this.handleApiError('Error fetching banks list', error);
		}
	}

	async resolveAccountName({ accountNumber, bankCode }) {
		try {
			const response = await api.post(
				'/payment/resolveaccount',
				{
					account_number: accountNumber,
					bank_code: bankCode,
				},
				{
					withCredentials: true,
				},
			);
			this.trackApiCall('POST', '/payment/resolveaccount');
			return response.data;
		} catch (error) {
			this.trackApiCall('POST', '/payment/resolveaccount', false);
			this.handleApiError('Error resolving account name', error);
		}
	}

	async handlePaystackCallback(reference, callbackUrl) {
		console.log(`Original Callback URL: ${callbackUrl}`);

		// Extract base URL (remove query parameters)
		const baseCallbackUrl = callbackUrl.split('?')[0];

		console.log(
			`Cleaned Callback URL (without queries): ${baseCallbackUrl}`,
		);

		try {
			const response = await api.get(
				`/payment/paystack/callback?reference=${reference}&callbackURL=${encodeURIComponent(
					baseCallbackUrl,
				)}`,
				{
					withCredentials: true,
				},
			);

			this.trackApiCall(
				'GET',
				`/payment/paystack/callback?reference=${reference}&callbackURL=${encodeURIComponent(
					baseCallbackUrl,
				)}`,
			);

			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/payment/paystack/callback?reference=${reference}&callbackURL=${encodeURIComponent(
					baseCallbackUrl,
				)}`,
				false,
			);
			this.handleApiError('Error handling Paystack callback', error);
		}
	}

	// Utility methods

	async searchMerchantData(query) {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/search`,
				{
					params: { query },
				},
			);
			this.trackApiCall('GET', `/merchant/${this.merchantId}/search`);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/search`,
				false,
			);
			throw new Error('Could not fetch search results');
		}
	}

	async searchAddresses(address) {
		try {
			const response = await api.post(
				'/location/search',
				{
					searchInput: address,
				},
				{ withCredentials: true },
			);
			this.trackApiCall('POST', '/location/search');
			return response.data.addresses;
		} catch (error) {
			this.trackApiCall('POST', '/location/search', false);
			this.handleApiError('Error searching addresses', error);
		}
	}

	async updateLocation(currentUserId, address) {
		try {
			const response = await api.patch(`/merchant/${currentUserId}`, {
				address,
			});
			this.trackApiCall('PATCH', `/merchant/${currentUserId}`);
			return response.data;
		} catch (error) {
			this.trackApiCall('PATCH', `/merchant/${currentUserId}`, false);
			this.handleApiError('Error Updating Location', error);
		}
	}

	getCurrentUserLocation = async (latitude, longitude) => {
		try {
			const response = await api.get(
				`/location/${longitude}/${latitude}`,
				{
					withCredentials: true,
				},
			);

			this.trackApiCall(
				'GET',
				`/location/${longitude}/${latitude}`,
				true,
			);
			return response.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/location/${longitude}/${latitude}`,
				false,
			);
			this.handleApiError('Error fetching user location', error);
		}
	};

	async getMerchantNotifications() {
		try {
			const response = await api.get(
				`/merchant/${this.merchantId}/notifications`,
				{
					withCredentials: true,
				},
			);
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/notifications`,
			);
			return response.data.data;
		} catch (error) {
			this.trackApiCall(
				'GET',
				`/merchant/${this.merchantId}/notifications`,
				false,
			);
			this.handleApiError('Error fetching notifications', error);
		}
	}

	// Error handler
	handleApiError(message, error) {
		throw new Error(`${message}. Please try again later.`);
	}

	// Utility function to track API calls
	trackApiCall(method, endpoint, success = true) {
		if (window.gtag) {
			window.gtag(
				'event',
				success ? 'api_call_success' : 'api_call_failure',
				{
					event_category: 'API',
					event_label: `${method} ${endpoint}`,
					value: success ? 1 : 0,
				},
			);
		}
	}
}

export default ApiService;

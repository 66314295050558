import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DataDisplayRenderer from '../components/DataDisplay';
import Layout from '../../../Layout';
import { Fa42Group } from 'react-icons/fa6';
import DeleteModal from '../components/deleteModal';
import { useAPI } from '../../../apis/api_context';
import { toast } from 'sonner';
import ShareProductModal from '../components/shareProductModal';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../store/userSlice';
import EditProductModal from '../components/productflow/EditProductModal';
import { useAuth } from '../../../apis/auth_context';
import Dropdown from '../components/dropdown';

const Store = () => {
	const dispatch = useDispatch();
	const { store, coupons } = useSelector((state) => state.user);
	const apiservice = useAPI();
	const { refreshUserData } = useAuth();
	const navigate = useNavigate();
	const location = useLocation();
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null);
	const [deletionType, setDeletionType] = useState(null);
	const [isShareModalOpen, setShareModalOpen] = useState(false);
	const [itemToShare, setItemToShare] = useState(null);
	const [shareType, setShareType] = useState(null);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
	const [editType, setEditType] = useState(null);
	const [itemToEdit, setItemToEdit] = useState(null);
	const [loading, setLoading] = useState(true);
	const [selectedCategory, setSelectedCategory] = useState('All Products');

	const fetchStore = useCallback(async () => {
		try {
			setLoading(true);
			const response = await apiservice.getStore();

			dispatch(setUserData({ store: response }));
		} catch (error) {
		} finally {
			setLoading(false);
		}
	}, [apiservice, dispatch]);

	useEffect(() => {
		fetchStore();
	}, [fetchStore]);

	const openDeleteModal = (item, type) => {
		setItemToDelete(item);
		setDeletionType(type);
		setDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
		setItemToDelete(null);
		setDeletionType(null);
	};

	const openShareModal = (item, type) => {
		setItemToShare(item);
		setShareType(type);
		setShareModalOpen(true);
	};

	const closeShareModal = () => {
		setShareModalOpen(false);
		setItemToShare(null);
		setShareType(null);
	};
	const openEditModal = (item, type) => {
		// // console.log('Item to edit at Store', item);
		setItemToEdit(item);
		setEditType(type);
		setEditModalOpen(true);
	};
	const closeEditModal = () => {
		setEditModalOpen(false);
		setItemToEdit(null);
		setEditType(null);
	};
	// console.log('Coupon delete', itemToDelete);
	const confirmDelete = async () => {
		if (deletionType === 'product') {
			await deleteProduct(itemToDelete._id);
		} else if (deletionType === 'coupon') {
			await deleteCoupon(itemToDelete._id);
		} else if (deletionType === 'category') {
			await deleteCategory(itemToDelete._id);
		}
		closeDeleteModal();
		fetchStore();
		refreshUserData();
	};

	const deleteProduct = async (productId) => {
		try {
			const response = await apiservice.deleteProduct(productId);
			if (response.status === 200) {
				toast.success('Product deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			//
			toast.error('Error deleting product. Please try again.');
		}
	};
	const deleteCoupon = async (couponId) => {
		try {
			const response = await apiservice.deleteCoupon(couponId);
			if (response.status === 200) {
				toast.success('Coupon deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			toast.error('Error deleting coupon. Please try again.');
		}
	};
	const deleteCategory = async (categoryId) => {
		try {
			const response = await apiservice.deleteCategory(categoryId);
			if (response.status === 200) {
				toast.success('Category deleted successfully!');
				await fetchStore();
			}
		} catch (error) {
			toast.error('Error deleting category. Please try again.');
		}
	};
	const couponActions = (coupon) => [
		{
			label: 'Delete',
			onClick: () => openDeleteModal(coupon, 'coupon'),
		},
		{
			label: 'Share',
			onClick: () => openShareModal(coupon, 'coupon'),
		},
	];
	const categoryActions = (category) => [
		{
			label: 'Delete',
			onClick: () => openDeleteModal(category, 'category'),
		},
		{
			label: 'Share',
			onClick: () => openShareModal(category, 'category'),
		},
	];
	const productActions = (product) => [
		{
			label: 'Edit',
			onClick: () => openEditModal(product, 'product'),
		},
		{
			label: 'Delete',
			onClick: () => openDeleteModal(product, 'product'),
		},
		{
			label: 'Share',
			onClick: () => {
				openShareModal(product, 'product');
			},
		},
	];
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState({});

	const handleCloseDropdown = () => {
		setIsDropdownOpen(false);
	};
	const filterHeaderRef = useRef(null);

	const tabs = useMemo(() => {
		const handleSelect = (option) => {
			setSelectedCategory(option);
			handleCloseDropdown();
		};

		const handleToggleDropdown = (e) => {
			const rect = filterHeaderRef.current.getBoundingClientRect();
			setDropdownPosition({
				top: rect.bottom + window.scrollY, // Position dropdown below the header
				left: rect.left + window.scrollX, // Align dropdown with the header
			});
			setIsDropdownOpen(!isDropdownOpen);
		};
		const products =
			store.categories
				?.map((category) =>
					category.products.map((product) => ({
						_id: product?._id,
						name: product?.name,
						description: product?.description,
						unitPrice: product?.unitPrice,
						availableQuantity: product?.availableQuantity,
						images: product?.images,
						categoryName: category?.name,
						attributes: product?.attributes
							? Object.entries(product.attributes)
									.map(
										([key, value]) =>
											`${key}: ${
												typeof value === 'object'
													? JSON.stringify(value)
													: value
											}`,
									)
									.join(', ')
							: '',
					})),
				)
				.flat() || [];

		const filteredProducts =
			selectedCategory === 'All Products'
				? products
				: products.filter(
						(product) => product.categoryName === selectedCategory,
				  );
		// console.log("Coupons", coupons);
		// Map coupons
		const mappedCoupons =
			coupons?.map((coupon) => ({
				_id: coupon._id,
				code: coupon.code,
				discountValue: coupon.discountValue,
				expiry: coupon.expiry
					? new Date(coupon.expiry).toLocaleDateString('en-GB', {
							day: '2-digit',
							month: '2-digit',
							year: 'numeric',
					  })
					: "Doesn't expire",
				active: coupon.active ? 'Yes' : 'No',
				usedBy:
					coupon.usedBy.length > 0
						? coupon.usedBy.join(', ')
						: 'None',
			})) || [];

		const categories =
			store.categories?.map((category) => ({
				_id: category._id,
				name: category.name,
				products: category.products.length,
			})) || [];

		//Dropdown to sort products based on categories and all products
		const productItems = [
			{ _id: 'all-products', name: 'All Products' },
			...categories,
		];

		return [
			{
				name: 'Products',
				headers: [
					'Name',
					'Description',
					'Unit Price',
					'Available Quantity',
				],
				data: filteredProducts,
				view: 'card',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have not added a product yet
						</p>
					</div>
				),
				filterHeader: (
					<div
						ref={filterHeaderRef}
						className='flex items-center gap-4 relative'
						onClick={handleToggleDropdown}>
						<button className='flex justify-center items-center w-6 h-6'>
							<i
								className='fi fi-br-settings-sliders inline-flex items-center justify-center h-full w-full'
								style={{ color: '#7B8783' }}></i>
						</button>
						<button
							className=''
							onClick={handleToggleDropdown}>
							{selectedCategory || 'All Products'}
						</button>
					</div>
				),
				filterDropdown: (
					<>
						{isDropdownOpen && (
							<div
								className='mt-7'
								style={{
									position: 'absolute',
									top: `${dropdownPosition.top}px`,
									left: `${dropdownPosition.left}px`,
								}}>
								<Dropdown
									actions={productItems.map((item) => ({
										label: item.name,
										onClick: () => handleSelect(item.name),
									}))}
									onClose={handleCloseDropdown}
									width='min-w-[150px]'
								/>
							</div>
						)}
					</>
				),
			},

			{
				name: 'Coupons',
				headers: [
					'Code',
					'Discount Value',
					'Expiry',
					'Active',
					'Used By',
				],
				data: mappedCoupons,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have not added a coupon yet
						</p>
					</div>
				),
			},

			{
				name: 'Categories',
				headers: ['Category', 'Products'],
				data: categories,
				view: 'table',
				emptyStateMessage: (
					<div className='flex flex-col items-center justify-center'>
						<Fa42Group className='text-6xl text-gray-300 mb-4' />
						<p className='text-xl font-semibold text-gray-500'>
							You have not added a category yet
						</p>
					</div>
				),
			},
		];
	}, [
		store.categories,
		selectedCategory,
		coupons,
		dropdownPosition.left,
		dropdownPosition.top,
		isDropdownOpen,
	]);

	const [activeTab, setActiveTab] = useState(() => {
		const savedTab = localStorage.getItem('activeTab');
		return savedTab || tabs[0].name;
	});

	const [currentPage, setCurrentPage] = useState(() => {
		const savedPage = localStorage.getItem('currentPage');
		return savedPage ? parseInt(savedPage, 10) : 1;
	});

	const activeTabData = tabs.find((tab) => tab.name === activeTab) || {};

	useEffect(() => {
		localStorage.setItem('activeTab', activeTab);
		localStorage.setItem('currentPage', currentPage.toString());

		const path = `/store/${activeTab.toLowerCase()}`;
		if (location.pathname !== path) {
			navigate(path);
		}
	}, [activeTab, currentPage, navigate, location.pathname]);

	useEffect(() => {
		const pathTab = location.pathname.split('/')[2];
		const matchingTab = tabs.find(
			(tab) => tab.name.toLowerCase() === pathTab,
		);
		if (matchingTab) {
			setActiveTab(matchingTab.name);
		}

		return () => {
			localStorage.removeItem('activeTab');
			localStorage.removeItem('currentPage');
		};
	}, [location.pathname, tabs]);

	const handleCardClick = (product) => {
		navigate(`/store/products/${product._id}`);
	};

	return (
		<Layout>
			<div className='flex flex-col h-[89vh] no-scrollbar'>
				<div className='flex-grow h-full no-scrollbar '>
					<div>
						{loading ? (
							<div className='flex items-center justify-center h-full'>
								<p className='text-xl font-semibold text-gray-500'>
									Loading Store Info...
								</p>
							</div>
						) : (
							<DataDisplayRenderer
								tabs={tabs}
								defaultView={activeTabData.view}
								setActiveTab={setActiveTab}
								activeTab={activeTab}
								onCardClick={(item) => handleCardClick(item)}
								onTableClick={(item) => {}}
								onActionClick={(item) =>
									activeTab === 'Products'
										? productActions(item)
										: activeTab === 'Coupons'
										? couponActions(item)
										: categoryActions(item)
								}
								emptyStateMessage={
									activeTabData.emptyStateMessage
								}
								currentPage={currentPage}
								setCurrentPage={setCurrentPage}
								filterHeader={activeTabData.filterHeader}
								filterDropdown={activeTabData.filterDropdown}
							/>
						)}
					</div>
				</div>
				<DeleteModal
					isOpen={deleteModalOpen}
					onClose={closeDeleteModal}
					onConfirm={confirmDelete}
					itemName={
						itemToDelete
							? deletionType === 'coupon'
								? itemToDelete.code
								: itemToDelete.name
							: ''
					}
				/>
				{isShareModalOpen && (
					<ShareProductModal
						closeShareModal={closeShareModal}
						selectedProduct={itemToShare}
						type={shareType}
					/>
				)}
				{isEditModalOpen && (
					<EditProductModal
						closeProductModal={closeEditModal}
						selectedProduct={itemToEdit}
						type={editType}
					/>
				)}
			</div>
		</Layout>
	);
};

export default Store;

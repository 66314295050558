import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ErrorBoundary from './utils/error_boundary';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import App from './App';

const RootWithBoundary = () => {
	return (
		<ErrorBoundary>
			<Root />
		</ErrorBoundary>
	);
};

const theme = createTheme();

const Root = () => {
	const [ws, setWs] = useState(null);

	const isTestEnv = process.env.REACT_APP_ENV === 'test';

	useEffect(() => {
		// Establish WebSocket connection
		const wsProtocol =
			window.location.protocol === 'https:' ? 'wss://' : 'ws://';

		const host = isTestEnv
			? 'localhost:1222'
			: process.env.REACT_APP_CENTRAL_SERVER;

		const socket = new WebSocket(`${wsProtocol}${host}`);

		setWs(socket);

		socket.onopen = () => {
			// // console.log('WebSocket connection established');
		};

		socket.onclose = () => {
			// console.log('WebSocket connection closed');
		};

		socket.onmessage = (message) => {
			// TODO: Handle WebSocket messages
			// console.log(
			// 	'WebSocket message received:',
			// 	JSON.parse(message.data),
			// );
		};

		socket.onerror = (error) => {
			console.error('WebSocket error:', error);
		};

		// Clean up WebSocket on component unmount
		return () => {
			if (socket) {
				socket.close();
			}
		};
	}, [isTestEnv]);

	return (
		<React.StrictMode>
			<ThemeProvider theme={theme}>
				<App ws={ws} />
			</ThemeProvider>
		</React.StrictMode>
	);
};

ReactDOM.createRoot(document.getElementById('root')).render(
	<RootWithBoundary />,
);

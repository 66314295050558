import React, { useState, useRef, useEffect } from 'react';
import { FaBell, FaPlus, FaSearch } from 'react-icons/fa';
// import { FaBell, FaPlus, FaSearch, FaTimes } from 'react-icons/fa';
import { Drawer } from 'antd';
import Button from './button';
import Dropdown from './dropdown';
import SettingsModal from './settingsModal';
import ProductModal from './productflow/productModal';
import { useAPI } from '../../../apis/api_context';
import { FaGear } from 'react-icons/fa6';
import NotificationModal from './notificationModal';
import SubscriptionBanner from './subscriptionBanner';
// import { toast } from 'sonner';
import CouponModal from './couponModal';
import { SubscriptionModal } from './SubscriptionModal';

const Header = ({
	pageTitle,
	merchantData,
	refreshUser,
	isProductModalOpen,
	setIsProductModalOpen,
	subscription,
	refreshUserData,
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isCreateDropdownOpen, setIsCreateDropdownOpen] = useState(false);
	const [isCouponModalOpen, setIsCouponModalOpen] = useState(false);
	const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
	const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
	const [isNotificationModalOpen, setIsNotificationModalOpen] =
		useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchResults, setSearchResults] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] =
		useState(false);

	const searchInputRef = useRef(null);
	const apiService = useAPI();

	useEffect(() => {
		if (searchValue.trim()) {
			const fetchSearchResults = async () => {
				setLoading(true);
				try {
					const results = await apiService.searchMerchantData(
						searchValue,
					);
					setSearchResults(results);
				} catch (error) {
					setSearchResults(null);
				} finally {
					setLoading(false);
				}
			};

			const delayDebounceFn = setTimeout(fetchSearchResults, 300);
			return () => clearTimeout(delayDebounceFn);
		} else {
			setSearchResults(null);
		}
	}, [searchValue, apiService]);

	const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
	const toggleCreateDropdown = () =>
		setIsCreateDropdownOpen(!isCreateDropdownOpen);
	const toggleSettingsModal = () =>
		setIsSettingsModalOpen(!isSettingsModalOpen);
	const handleOpenSearchModal = () => {
		setIsSearchModalOpen(true);
		setTimeout(() => searchInputRef.current?.focus(), 100);
	};

	const handleCloseSearchModal = () => {
		setIsSearchModalOpen(false);
		setSearchValue('');
		setSearchResults(null);
	};

	const handleOpenNotificationsModal = async () => {
		setIsNotificationModalOpen(true);
	};

	const dropdownActions = [
		// TODO: Propagate Coupon. Simply Uncomment this
		{
			label: 'Create New Product',
			onClick: () => setIsProductModalOpen(true),
		},
		{
			label: 'Create New Coupon',
			onClick: () => setIsCouponModalOpen(true),
		},
	];
	

	const renderResults = () => {
		if (loading) return <div>Loading...</div>;
		if (!searchResults || Object.keys(searchResults).length === 0)
			return <div>No results found.</div>;
	
		return (
			<div>
				{Object.entries(searchResults).map(([key, results]) => {
					if (results.length > 0) {
						return (
							<div key={key} className="mb-4">
								<h3 className="font-bold text-lg">{key.toUpperCase()}</h3>
								<ul className="pl-4 list-disc">
									{results.map((item, index) => (
										<li key={index} className="mb-2">
											{key === 'storeInfo' && (
												<>
													<p><strong>ID:</strong> {item.id}</p>
													<p><strong>Store Link:</strong> <a href={item.storeLink} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">{item.storeLink}</a></p>
												</>
											)}
	
											{key === 'products' && (
												<>
													<p><strong>ID:</strong> {item.id}</p>
													<p><strong>Name:</strong> {item.name|| 'No name available'}</p>
													<p><strong>Description:</strong> {item.description || 'No description available'}</p>
												</>
											)}
	
											{key === 'coupons' && (
												<>
													<p><strong>ID:</strong> {item.id}</p>
													<p><strong>Code:</strong> {item.code}</p>
												</>
											)}
	
											{key === 'subscriptions' && <p>No subscriptions found.</p>}
											{key === 'payouts' && <p>No payouts found.</p>}
											{key === 'orders' && <p>No orders found.</p>}
											{key === 'customers' && <p>No customers found.</p>}
											{key === 'withdrawalRequests' && <p>No withdrawal requests found.</p>}
										</li>
									))}
								</ul>
							</div>
						);
					} else if (
						['subscriptions', 'payouts', 'orders', 'customers', 'withdrawalRequests'].includes(key)
					) {
						return (
							<div key={key} className="mb-4">
								<h3 className="font-bold text-lg">{key.toUpperCase()}</h3>
								<p>No {key} found.</p>
							</div>
						);
					}
					return null;
				})}
			</div>
		);
	};
	

	// const renderResults = () => {
	// 	if (loading) return <div>Loading...</div>;
	// 	if (!searchResults || Object.keys(searchResults).length === 0)
	// 		return <div>No results found.</div>;

	// 	return (
	// 		<div>
	// 			{Object.entries(searchResults).map(([key, results]) => {
	// 				if (results.length > 0) {
	// 					return (
	// 						<div key={key}>
	// 							<h3>{key.toUpperCase()}</h3>
	// 							<ul>
	// 								{results.map((item, index) => (
	// 									<li key={index}>
	// 										<pre>
	// 											{JSON.stringify(item, null, 2)}
	// 										</pre>
	// 									</li>
	// 								))}
	// 							</ul>
	// 						</div>
	// 					);
	// 				}
	// 				return null;
	// 			})}
	// 		</div>
	// 	);
	// };

	return (
		<>
			<header className='bg-white border-b'>
				<div className='hidden md:flex justify-between items-center p-4'>
					<h1 className='text-lg font-semibold'>{pageTitle}</h1>
					<div className='flex items-center gap-4'>
						<SubscriptionBanner
							tier={subscription.tier}
							onClick={() => setIsSubscriptionModalOpen(true)}
						/>

						<div className='relative'>
							<Button
								type='primary'
								label='Create'
								onClick={toggleCreateDropdown}
								iconLeft={<FaPlus />}
							/>
							{isCreateDropdownOpen && (
								<Dropdown
									actions={dropdownActions}
									onClose={toggleCreateDropdown}
									position='right-0 mt-2'
									width='w-[300px]'
									space='gap-4'
									padding='p-4'
								/>
							)}
						</div>

						<div className='flex'>
							<Button
								type='tertiary'
								label={<FaSearch size={20} />}
								onClick={handleOpenSearchModal}
							/>

							<div className='relative'>
								<Button
									type='tertiary'
									label={<FaBell size={20} />}
									onClick={handleOpenNotificationsModal}
								/>
								{isNotificationModalOpen && (
									<NotificationModal
										position='right-0 top-14'
										onClose={() =>
											setIsNotificationModalOpen(false)
										}
									/>
								)}
							</div>
							<Button
								type='tertiary'
								label={<FaGear size={20} />}
								onClick={toggleSettingsModal}
							/>
						</div>
					</div>
				</div>

				<Drawer
					anchor='left'
					open={isSidebarOpen}
					onClose={toggleSidebar}
				/>

				{isSearchModalOpen && (
					<div
						className='fixed inset-0 z-[1000] flex items-center justify-center bg-primary bg-opacity-70'
						onClick={handleCloseSearchModal}>
						<div
							className='bg-white w-[35vw] max-h-[90%] overflow-y-auto no-scrollbar scroll-smooth flex flex-col rounded-[12px]'
							onClick={(e) => e.stopPropagation()}>
							<input
								type='text'
								ref={searchInputRef}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
								placeholder='Type to search...'
								className='p-4 border-b'
								autoFocus
							/>
							<div className='p-4'>{renderResults()}</div>
						</div>
					</div>
				)}

				{isSettingsModalOpen && (
					<SettingsModal
						closeModal={toggleSettingsModal}
						refreshUser={refreshUser}
					/>
				)}

				{isCouponModalOpen && (
					<CouponModal
						closeCouponModal={() => setIsCouponModalOpen(false)}
					/>
				)}
				{isProductModalOpen && (
					<ProductModal
						closeProductModal={() => setIsProductModalOpen(false)}
						merchantData={merchantData}
						refreshUser={refreshUser}
					/>
				)}
			</header>
			<SubscriptionModal
				isOpen={isSubscriptionModalOpen}
				onClose={() => setIsSubscriptionModalOpen(false)}
				currentTier={subscription.tier}
			/>
		</>
	);
};

export default Header;
